import './scss/main.scss'

import $ from 'jquery';

$(".button-menu").click(function(){
	$(".menu-mobile").addClass("active");
});

$(".button-menu-close").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(".menu-mobile li a").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(document).ready(function(){
    $("a.row-scroll").on('click', function(event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top - 80
        }, 1000);
    });
});